import { useRef } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Skeleton from 'react-loading-skeleton';
// import ShowCurrencies from '../../../utils/showCurrencies';
// import useHandleAddProduct from '../../../utils/handleAddProduct';
import 'react-lazy-load-image-component/src/effects/blur.css';
// import ConvertAUD from '../../../utils/convertAUD';
import ConvertUSD from '../../../utils/convertUSD';
import CustomButton from '../../../components/general/button';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { endDateOfAppealCampaign, endDateOfLimbsAppealCampaign } from '../details';
// import IsGTMScriptLoaded from '../../../utils/isGTMScriptLoaded';
// import limbsImg from '../../../images/landings/palestine/big-Limbs_of_Hope_Campaign_Website_Image.jpg';
import { generateLinkWithLang } from '../../../utils/linkGenerator';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useTranslation } from 'react-i18next';

const AppealFundraiserProductBoxV2 = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const ref = useRef(null);
  const navigate = useNavigate();
  const refImg = useRef(null);
  const refLazyImg = useRef(null);
  const { product, currency, goal, campaign } = props;
  const { i18n } = useTranslation();
  const lang = i18n.language;
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  // const handleViewItemDataLayer = (tempProduct, currency) => {

  //     let dataLayerTemp = {
  //         event: 'view_item',
  //         ecommerce: {
  //             currency: currency.toUpperCase(),
  //             items: [{
  //                 item_id: tempProduct.product_creator,
  //                 item_name: tempProduct.product_name,
  //                 item_brand: tempProduct.category || "",
  //                 item_category: tempProduct.category || "",
  //                 item_description: tempProduct.product_description || '',
  //                 price: tempProduct[currency.toLowerCase()],
  //             }]
  //         }
  //     }
  //     window.dataLayer = window.dataLayer || [];
  //     IsGTMScriptLoaded() && window.dataLayer.push(dataLayerTemp);
  // }
  const handlePercentage = (total_sale, goal) => {
    return `${((Number(total_sale) / Number(goal)) * 100).toFixed(2)}%`;
  };
  const handleDaysLeft = () => {
    var now = moment(new Date());
    var after = moment(new Date(campaign === 'limbs-of-hope' ? endDateOfLimbsAppealCampaign : endDateOfAppealCampaign), 'DD-MM-YYYY HH:mm:ss');
    var timeDifference = after.diff(now, 'days');
    return timeDifference;
  };
  const ShowCurrencies = (currency, _price, showAbbr = true) => {
    let tempCurrency = currency && currency.toUpperCase();
    let price = Number(_price);
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    };
    if (price && Number(price) % 1 !== 0) {
      price = Number(price).toLocaleString('en', options);
    }
    let _final = '';
    price = price ? price.toLocaleString() : 0;
    switch (tempCurrency) {
      case 'GBP':
        _final = `${getSymbolFromCurrency(tempCurrency.toLowerCase())}${price}`;
        break;
      case 'EUR':
        _final = `${getSymbolFromCurrency(tempCurrency.toLowerCase())}${price}`;
        break;
      case 'MYR':
        _final = `${price}`;
        break;
      case 'IDR':
        _final = `Rp${price}`;
        break;
      case 'AED':
        _final = `Dh${price}`;
        break;
      default:
        _final = `$${price}`;
        break;
    }
    return _final;
  };
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  /* -------------------------------------------------------------------------- */
  return (
    <div className="w-full rounded-xl shadow-lg bg-white overflow-hidden max-[767px]:max-w-[355px] mx-auto border border-solid border-[#C3C3C3]">
      <div className="overflow-hidden rounded-md w-full p-2 pb-0 ">
        <div
          ref={refImg}
          className="overflow-hidden cursor-pointer max-h-[250px]- max-h-[395px] rounded-md w-full flex items-center "
          onClick={() =>
            product.link.includes('launchgood') ? (window.location.href = product.link) : navigate(generateLinkWithLang(`/${product.link}`, lang))
          }
        >
          {product ? (
            product.product_image_link ? (
              <LazyLoadImage
                ref={refLazyImg}
                alt={product.product_name}
                effect="blur"
                width={`100%`}
                delayTime={500}
                className="rounded-md"
                afterLoad={() => {
                  // handleViewItemDataLayer(product, currency)
                }}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = '/images/general/logo-replace.png';
                }}
                // src={campaign === 'limbs-of-hope'
                //     ? limbsImg
                //     : product.product_image_link
                // }
                src={product.product_image_link}
              />
            ) : (
              <img className="w-full" src="/images/general/logo-replace.png" alt="" />
            )
          ) : (
            <Skeleton height={344} />
          )}
        </div>
      </div>
      <div className="p-2 md:p-3 md:pb-5">
        <h3
          className="text-DarkerBlue sm:text-md md:text-md lg:text-lg xl:text-xl font-medium h-[48px] lg:h-[56px] mb-2 flex items-center cursor-pointer"
          onClick={() =>
            product.link.includes('launchgood') ? (window.location.href = product.link) : navigate(generateLinkWithLang(`/${product.link}`, lang))
          }
        >
          {product ? product.product_name : <Skeleton height={56} />}
        </h3>
        <div className="relative">
          {product ? (
            <>
              <div className={`my-2 relative overflow-hidden max-h-[60px] md:max-h-[96px]`}>
                <p ref={ref} className={`text-[#78716C] text-xs sm:text-sm overflow-hidden transition-all duration-200 min-h-[96px]`}>
                  {product.product_description}
                </p>
              </div>
            </>
          ) : (
            <Skeleton count={4} />
          )}
        </div>
        <div className="basis-full flex-col md:basis-1/2 flex justify-center pt-1 md:pt-2">
          <div className="flex items-center justify-between mb-2">
            <div className="flex w-full text-sm">
              <strong className="text-DarkerBlue font-bold mr-0.5">{product.total_supporters}</strong>
              <span className="inline-block lg:w-auto mr-1">Soutiens,</span>
              <strong className="text-DarkerBlue font-bold mr-0.5">{product.days ? product.days : handleDaysLeft()}</strong>
              <span className="inline-block lg:w-auto">jours restants</span>
            </div>
          </div>
          <div className="flex w-full h-2 bg-[#E3E3E3] bg-opacity-10- mb-2">
            <div className={`bg-[#F60362]`} style={{ width: `${handlePercentage(product.total_sale, ConvertUSD(currency, goal, false))}` }}></div>
          </div>
        </div>
        <div className="basis-full flex justify-between items-center mt-2">
          <div className="flex flex-col w-full">
            <div className="text-lg md:text-2xl text-DarkerBlue basis-full font-bold flex items-center">
              {product.total_sale && ShowCurrencies(currency, Math.round(product.total_sale), false)}
              <span className="text-xs ml-0.5">{currency}</span>
            </div>
            <div className="flex flex-wrap w-full text-xs items-center">
              <span className="text-[#78716C] text-[10px]">collectés sur un objectif de</span>
              <div className="text-DarkerBlue font-bold ml-0.5 text-sm">
                {ShowCurrencies(currency, Math.round(ConvertUSD(currency, goal, false)))}
              </div>
            </div>
          </div>
          {product ? (
            <CustomButton
              onClick={() =>
                product.link.includes('launchgood') ? (window.location.href = product.link) : navigate(generateLinkWithLang(`/${product.link}`, lang))
              }
              type="button"
              title={'Faire un don'}
              className={`!rounded-full !text-[10px] !px-2 whitespace-nowrap`}
            />
          ) : (
            <Skeleton height={43} />
          )}
        </div>
      </div>
    </div>
  );
};

export default AppealFundraiserProductBoxV2;

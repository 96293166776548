import { useEffect, useState } from 'react';
import Layout from '../../components/general/layout';
import aliBanner from '../../images/ali-banat/ali-banat-banner.jpg';
// import AliBannerMobile from '../images/ali-banat/head-banner-mobile.jpg';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import { useSelector } from 'react-redux';
import { selectProducts } from '../../store/products';
import ProductBox from '../../components/general/productBox';

const AliBanatPage = () => {
  /* ----------------------------- Start variables ---------------------------- */
  // const [youtubeID] = useState('dzL6BLPAFBo');
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const seo = SeoData[selectedcountryCode];
  const products = useSelector(state => selectProducts(state));
  const [aliBanatProducts, setAliBanatProducts] = useState([0, 0, 0, 0, 0]);
  const [isLoaded, setIsLoaded] = useState(false);
  const selectedCurrency = useSelector(selectSelectedCurrency);
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const getAllProducts = () => {
    setIsLoaded(true);
    const tempProducts = [];

    products.forEach(item => {
      item.sub_categories.forEach(inner_item => {
        inner_item.products.forEach(inner_item_product => {
          if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01') {
            tempProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'MKD-WEL-SDQ-2023-01') {
            tempProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'MKD-GEN-MOR-2023-01-119') {
            tempProducts[2] = inner_item_product;
          }
        });
      });
    });
    setAliBanatProducts(tempProducts);
  };
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    products && Object.keys(products).length && !isLoaded && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  useEffect(() => {
    // window.scroll({top: 0, left: 0, behavior: 'smooth' })
    window.scroll(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /* -------------------------------------------------------------------------- */
  return (
    <Layout hideFooter>
      {seo && (
        <Helmet>
          <title>{`${seo['aboutUs']['aliBanat']['title']}`}</title>
          <meta name="title" content={`${seo['aboutUs']['aliBanat']['title']}`} />
          <meta name="description" content={`${seo['aboutUs']['aliBanat']['description']}`} />
        </Helmet>
      )}
      <div className="h-[300px] bg-cover sm:hidden w-full bg-[url('../src/images/ali-banat/head-banner-mobile.jpg')]" />

      {
        <img
          src={aliBanner}
          alt="Ali Banat"
          className="w-full hidden sm:block"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = '/images/general/logo-replace.png';
            currentTarget.className = 'max-h-[180px] mx-auto';
          }}
        />
      }
      <div className="md:container md:mx-auto mt-10 sm:mt-20 mb-10">
        <div className="grid grid-cols-12 px-4 md:px-2 lg:px-0">
          <div className="px-4 sm:px-6 md:px-10 lg:px-16  col-span-12   rounded-md bg-white pt-6 sm:py-16">
            <h1 className="col-span-12 font-gotcha text-center text-[24px] sm:text-2xl md:text-5xl text-[#253B7E]  ">
              Ali Banat : Fondateur de l'association MATW
            </h1>
            <h2 className="col-span-12  font-brandingBold text-center text-[30px] sm:text-2xl pb-4 sm:py-10 md:text-6xl text-[#253B7E] ">
              De la Dunya au Destin
            </h2>
            <div className="w-full mt-5- col-span-12 ">
              <div className="col-span-12 h-[300px] md:h-[400px] lg:h-[600px]">
                <iframe
                  className="video rounded"
                  width={'100%'}
                  height={'100%'}
                  title="Gifted with Cancer - Ali Banat"
                  sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                  src={`https://youtube.com/embed/Duf77T3uQnI?autoplay=0?feature=oembed`}
                ></iframe>
              </div>
            </div>
            <div className=" text-[#78716C] my-6 font-brandingMedium text-[16px] sm:text-[24px] lead">
              <p className="">
                Né et élevé à Sydney, en Australie, Ali Banat était un homme ordinaire. Prospère et travailleur, Ali était devenu un homme d'affaires
                indépendant à l'âge de 29 ans.
                <br />
                <br />
                Comme beaucoup d'autres personnes de son âge ayant du succès, il menait une vie d'abondance et de luxe, partageant les fruits de son
                travail acharné avec sa famille et ses amis.
                <br /> <br />
                Il aimait ce qu'il avait et la vie qu'il menait.
                <br /> <br />
                Tout a changé avec un diagnostic mortel. Un diagnostic qui servirait de rappel que le temps sur terre est limité. Un cadeau.
                <br /> <br />
                Lorsque Ali a reçu ce diagnostic, il a commencé à réfléchir à la vie qu'il avait menée jusqu'à ce moment précis et a décidé qu'il
                n'avait pas suffisamment investi dans sa Akhirah. Il ne pouvait pas présenter ce qu'il avait - toutes les choses matérielles qu'il
                avait accumulées - à Allah SWT.
                <br /> <br />
                Ali devait faire un choix, sachant que sa vie était considérablement écourtée. Il aurait pu facilement sombrer dans le désespoir,
                comme c'est souvent le cas pour la plupart des personnes diagnostiquées avec un cancer.
                <br /> <br />
                Ali a choisi de voir ce diagnostic comme une occasion de faire une différence dans ce monde et pour sa Akhirah.
                <br /> <br />
                Il a donc vendu tout ce qu'il avait, réservé un vol pour le Togo et ainsi a commencé le projet Muslims Around The World en 2016.
                <br /> <br />
                En utilisant sa propre richesse, Ali a commencé par nourrir et soutenir les orphelins et les veuves dans les communautés les plus
                pauvres du Togo. Il a investi dans des outils agricoles et des tracteurs afin que la communauté puisse cultiver et s'aider à sortir de
                la pauvreté. Il a construit des puits pour qu'ils aient de l'eau potable, une mosquée pour prier et une école pour que les enfants
                apprennent et jouent.
                <br /> <br />
                Il a commencé à construire un héritage qui s'est aujourd'hui étendu à plus de 30 pays à travers le monde.
                <br /> <br />
              </p>
              <p className="text-[#253B7E] font-brandingBold">
                {' '}
                Un héritage qui a nourri plus de 90 millions de personnes.
                <br /> <br />
                Un héritage que chacun d'entre nous peut maintenant appeler le nôtre.
                <br /> <br />
                Cela a commencé avec Ali. Cela continue avec VOUS.
              </p>
            </div>
            <div className="flex justify-center flex-wrap">
              {aliBanatProducts.map(prod => {
                return (
                  prod.name !== 'Pakistan Solar Powered Well' && (
                    <div className="basis-1/1 sm:basis-1/2 md:basis-1/3 p-2" key={prod.id}>
                      <ProductBox product={prod} currency={selectedCurrency} />
                    </div>
                  )
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AliBanatPage;
